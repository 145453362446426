import React, { PropsWithChildren } from 'react';
import {
  PriceRow,
  servicesPricingData,
} from '../../partials/ServicesPricing/services-pricing-data';
import Heading from '../../core/typography/Heading';

interface PricingBlockProps {
  pricingData?: PriceRow[];
}

export const PricingSchedule = ({
  pricingData,
}: {
  pricingData?: PriceRow[];
}) => {
  const getPricingArray = () => {
    return pricingData || servicesPricingData;
  };
  return (
    <div className="w-full md:w-1/2 pb-10 md:p-5 flex items-start md:items-center flex-col">
      <div>
        <Heading variant="h6" className="text-left mb-1">
          Tariffe orarie del servizio
        </Heading>
        <p className="text-paragraph text-gray-400 mb-5">
          (escluso il costo di eventuali biglietti per i mezzi pubblici)
        </p>
      </div>
      <table className="w-full md:w-1/2">
        <thead>
          <tr>
            <th>Ore</th>
            <th>Tariffa</th>
          </tr>
        </thead>
        <tbody>
          {getPricingArray().map(p => (
            <tr className="border-b border-gray-200" key={p.hour}>
              <td className="text-center text-paragraph">{p.hour}</td>
              <td className="text-center text-paragraph">{p.tariff}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

/**
 * Children will only be rendered
 * @props
 */
const PricingBlock = (props: PropsWithChildren<PricingBlockProps>) => {
  return (
    <div className="md:p-1">
      <div className="flex flex-wrap md:shadow-card">
        {props.children ? (
          <div className="w-full md:w-1/2 bg-gray-100 p-5 md:p-16">
            {props.children}
          </div>
        ) : null}
        <PricingSchedule />
      </div>
    </div>
  );
};

export default PricingBlock;
