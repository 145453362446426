import { ReactNode } from 'react';
import Heading from '../../core/typography/Heading';
import React from 'react';
import ButtonWithLink from '../ServicesPricing/buttonWithLink';

export interface PriceRow {
  hour: string;
  tariff: string;
}

export const servicesPricingData: PriceRow[] = [
  {
    hour: '1h',
    tariff: '15,00 €',
  },
  {
    hour: '1,5h',
    tariff: '22,00 €',
  },
  {
    hour: '2h',
    tariff: '29,00 €',
  },
  {
    hour: '2,5h',
    tariff: '35,50 €',
  },
  {
    hour: '3h',
    tariff: '42,00 €',
  },
  {
    hour: '3,5h',
    tariff: '48,00 €',
  },
  {
    hour: '4h',
    tariff: '54,00 €',
  },
  {
    hour: '4,5h',
    tariff: '59,50 €',
  },
  {
    hour: '5h',
    tariff: '65,00 €',
  },
  {
    hour: '5,5h',
    tariff: '70,00 €',
  },
  {
    hour: '6h',
    tariff: '75,50 €',
  },
  {
    hour: '6,5h',
    tariff: '79,50 €',
  },
  {
    hour: '7h',
    tariff: '84,00 €',
  },
  {
    hour: '7,5h',
    tariff: '88,00 €',
  },
  {
    hour: '8h',
    tariff: '92,00 €',
  },
  {
    hour: 'più di 8h',
    tariff: 'Su preventivo',
  },
];

export const getPricingBlocks = (
  datalayerAction: (cta: string) => void
): { title: string; content: ReactNode }[] => {
  return [
    {
      title: 'Servizio Base',
      content: (
        <>
          <Heading variant="h4" className="hidden md:block">
            Servizio Base
          </Heading>
          <p className="text-paragraph mb-5 text-2xl">
            L’operatore UGO accompagna l’utente a piedi o con i mezzi pubblici.
          </p>
          <p className="text-paragraph-condense mb-10">
            Se hai necessità di spostarti in auto puoi{' '}
            <span className="text-primary-500 font-bold">
              utilizzare quella di UGO{' '}
            </span>
            oppure{' '}
            <span className="text-primary-500 font-bold">
              permettere all’UGO di guidare la tua
            </span>
          </p>
          <Heading variant="h5" className="mb-1">
            Prima ora: 15€
          </Heading>
          <p className="text-paragraph font-bold mb-4">
            La durata minima del servizio è di 1 ora.
          </p>
          <ButtonWithLink
            variant="normal"
            btnText="Ottieni un preventivo su misura"
            link="/#hero"
            onClick={() => datalayerAction('preventivo')}
          />
          <p className="font-light text-gray-700">
            Sono esclusi i costi dei biglietti dei mezzi pubblici, che sono a
            carico dell’utente.
          </p>
        </>
      ),
    },
    {
      title: "Con l'auto di UGO",
      content: (
        <>
          <Heading variant="h4" className="hidden md:block">
            Con l`auto di UGO
          </Heading>
          <p className="text-paragraph mb-5 text-2xl">
            Il servizio prevede uno spostamento in auto che verrà gestito con la
            vettura personale del nostro operatore UGO
          </p>
          <Heading variant="h5" className="mb-1">
            Prima ora: 15€
          </Heading>
          <p className="text-paragraph font-bold mb-4">
            La durata minima del servizio è di 1 ora.
          </p>
          <Heading variant="h5" className="mb-4">
            + Rimborso spese di 0,50€/km*
          </Heading>
          <ButtonWithLink
            variant="normal"
            btnText="Ottieni un preventivo su misura"
            link="/#hero"
            onClick={() => () => datalayerAction('preventivo')}
          />
          <p className="font-light text-gray-700 mb-4">
            Qualora il punto di partenza richiesto fosse esterno al perimetro
            urbano, il rimborso spese della vettura sarà conteggiato
            considerando anche i km di distanza tra la città e il punto
            richiesto.
          </p>
          <p className="font-light text-gray-700">
            Eventuali spese extra relative a parcheggio, ZTL, pedaggi, sono
            escluse dal costo del servizio e si considerano tali anche quelle
            sostenute dall&apos;operatore per raggiungere o lasciare la
            destinazione dell&apos;utente.
          </p>
        </>
      ),
    },
    {
      title: 'Con la tua auto',
      content: (
        <>
          <Heading variant="h4" className="hidden md:block">
            Con la tua auto
          </Heading>
          <p className="text-paragraph mb-5 text-2xl">
            Il servizio prevede uno spostamento in auto che verrà gestito con la
            vettura personale dell’utente ma guidata dal nostro operatore UGO
          </p>
          <Heading variant="h5" className="mb-1">
            Prima ora: 15€
          </Heading>
          <p className="text-paragraph font-bold mb-4">
            La durata minima del servizio è di 1 ora.
          </p>
          <Heading variant="h5" className="mb-4">
            + Copertura assicurativa: 5€/ora di viaggio*
          </Heading>
          <ButtonWithLink
            variant="normal"
            btnText="Ottieni un preventivo su misura"
            link="/#hero"
            onClick={() => () => datalayerAction('preventivo')}
          />
          <p className="font-light text-gray-700 mb-4">
            * È sempre prevista copertura assicurativa kasko fornita da UGO. Il
            costo si applica solo sul tempo effettivo di spostamento.
          </p>
          <p className="font-light text-gray-700">
            Eventuali spese extra relative a parcheggio / ZTL / pedaggi e costo
            del carburante sono escluse dal costo del servizio.
          </p>
        </>
      ),
    },
    {
      title: 'Con una vettura speciale',
      content: (
        <>
          <Heading variant="h4" className="hidden md:block">
            Con una vettura speciale
          </Heading>
          <p className="text-paragraph mb-5 text-2xl">
            Il servizio prevede uno spostamento che verrà gestito con una
            vettura speciale con pedana per l’accesso della carrozzina
          </p>
          <Heading variant="h5" className="mb-4">
            UGO mette a disposizione il proprio operatore per affiancare
            l’utente e si affida a un fornitore esterno per il noleggio del
            mezzo (preventivo in base al fornitore).
          </Heading>
          <p className="text-paragraph-condense mb-10">
            Formuliamo un preventivo su misura in base agli accordi e tariffe
            attive con i nostri partner.
          </p>
          <ButtonWithLink
            variant="normal"
            btnText="Ottieni un preventivo su misura"
            link="/#hero"
            onClick={() => () => datalayerAction('preventivo')}
          />
        </>
      ),
    },
  ];
};
