import React from 'react';
import Button from '../../core/buttons/Button';
import { Link } from 'gatsby';

interface ButtonWithLinkInterface {
  btnText: string;
  link: string;
  variant: string;
  onClick?: () => void;
}

const ButtonWithLink = (props: ButtonWithLinkInterface) => {
  if (props.variant === 'small') {
    return (
      <Link to={props.link}>
        <Button
          size="small"
          variant="outlined"
          color="primary"
          onClick={props.onClick}
        >
          {props.btnText}
        </Button>
      </Link>
    );
  }
  return (
    <Link to={props.link}>
      <Button
        variant="filled"
        color="primary"
        // className="mb-5"
        style={{ marginBottom: '1.25rem' }}
        onClick={props.onClick}
      >
        {props.btnText}
      </Button>
    </Link>
  );
};

export default ButtonWithLink;
