import React from 'react';
import BackgroundImage from 'gatsby-background-image';
import Heading from '../../core/typography/Heading';
import Button from '../../core/buttons/Button';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { OtherPricingQuery } from '../../../../generated/graphql-types';
import { convertToBgImage } from 'gbimage-bridge';

const OtherPricing = ({
  accompagnamento,
  commissioni,
  compagnia,
}: {
  accompagnamento?: boolean;
  commissioni?: boolean;
  compagnia?: boolean;
}) => {
  const services = useStaticQuery<OtherPricingQuery>(graphql`
    query OtherPricing {
      accompagnamento: file(
        relativePath: { eq: "accompagnamento_service.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 600, placeholder: BLURRED, layout: CONSTRAINED)
        }
      }
      commissioni: file(relativePath: { eq: "commissioni_service.png" }) {
        childImageSharp {
          gatsbyImageData(width: 600, placeholder: BLURRED, layout: CONSTRAINED)
        }
      }
      compagnia: file(relativePath: { eq: "compagnia_service.png" }) {
        childImageSharp {
          gatsbyImageData(width: 600, placeholder: BLURRED, layout: CONSTRAINED)
        }
      }
    }
  `);

  const accompagnamentoBgImage = convertToBgImage(
    services.accompagnamento.childImageSharp.gatsbyImageData
  );
  const commissioniBgImage = convertToBgImage(
    services.commissioni.childImageSharp.gatsbyImageData
  );
  const compagniaBgImage = convertToBgImage(
    services.compagnia.childImageSharp.gatsbyImageData
  );

  return (
    <div className="flex flex-wrap -mx-4">
      {accompagnamento ? (
        <div className="w-full md:w-1/3 flex px-4 mb-10">
          <div className="flex flex-col flex-1">
            <div className="mb-auto">
              <BackgroundImage
                {...accompagnamentoBgImage}
                style={{ height: '220px', width: '100%' }}
                className="rounded-2xl overflow-hidden mb-5"
              />
              <Heading variant="h5">Prezzi accompagnamento</Heading>
            </div>
            <div>
              <Link to="/prezzi-accompagnamento/">
                <Button variant="filled" color="primary">
                  Vai ai prezzi
                </Button>
              </Link>
            </div>
          </div>
        </div>
      ) : null}
      {commissioni ? (
        <div className="w-full md:w-1/3 flex px-4 mb-10">
          <div className="flex flex-col flex-1">
            <div className="mb-auto">
              <BackgroundImage
                {...commissioniBgImage}
                style={{ height: '220px', width: '100%' }}
                className="rounded-2xl overflow-hidden mb-5"
              />
              <Heading variant="h5">Prezzi commissioni</Heading>
            </div>
            <div>
              <Link to="/prezzi-commissioni/">
                <Button variant="filled" color="primary">
                  Vai ai prezzi
                </Button>
              </Link>
            </div>
          </div>
        </div>
      ) : null}
      {compagnia ? (
        <div className="w-full md:w-1/3 flex px-4 mb-10">
          <div className="flex flex-col flex-1">
            <div className="mb-auto">
              <BackgroundImage
                {...compagniaBgImage}
                style={{ height: '220px', width: '100%' }}
                className="rounded-2xl overflow-hidden mb-5"
              />
              <Heading variant="h5">Prezzi compagnia</Heading>
            </div>
            <div>
              <Link to="/prezzi-compagnia/">
                <Button variant="filled" color="primary">
                  Vai ai prezzi
                </Button>
              </Link>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default OtherPricing;
